import Dexie from 'dexie';
export const db = new Dexie('permit');
import u from '@/lib/util';

// Declare tables, IDs and indexes
db.version(1).stores({
    org: 'oid,name',
    role: '[oid+id],oid,[code],name',
    taskResultSet: '[oid+id],oid,[id],name',
    type: '[typeOfType+code],[oid+typeOfType],code,name',
    workDiscipline: '[oid+id],oid,[code],name',
    workFlowType: '[oid+id],[id],oid,[code],name',
    workFlowSubType: '[oid+id],[id],oid,[code],[workFlowTypeID],name',
    holiday: '[oid+id],name,date',
    member: '[oid+id],name',
    sync:'++id,[oid+module],[oid+module+operation+key],[oid+operation],sync,operation,key'
});

db.reload= async function(fill,mod,sortBy) {
    fill.length = 0;
    if (db[mod] !== undefined) {
      await db[mod].each((item) => {
        fill.push(item);
      });
    }
    if(sortBy) {
      u.sortList(fill,sortBy)
    }
    return fill;
}


db.open();
