import {Loader} from "@googlemaps/js-api-loader";
import {IAddressLookup, LookupRequest, LookupResult} from "./addressLookup";
import AddressLookupFactory from "./addressLookup";

class _GoogleLookup implements IAddressLookup {

  name: string = 'google'
  google: any
  geocoder: any

  city:string|undefined
  bounds:number[][]|undefined
  loaded: boolean = false

  constructor() {
  }

  init(options: any): IAddressLookup {
    if(options===null) return this;
    if(options.city) this.city=options.city;
    if(options.bounds) this.bounds=options.bounds;

    if(!options.apiKey) return;
    if(this.loaded) return this;
    this.loaded=true;

    const loader = new Loader({
      apiKey: options.apiKey,
      version: "weekly",
      libraries: ["places"]
    });

    loader
      .load()
      .then((g) => {
        this.google=g;
        this.geocoder = new g.maps.Geocoder();
      })
      .catch(e => {
        console.error("failed to load google maps api")
      });

    return this;
  }

  lookup(search: LookupRequest): Promise<LookupResult[]> {
    let self = this;
    return new Promise<LookupResult[]>((resolve, reject) => {

      let searchParams = {address:search.address,bounds:undefined};

      let bounds = search.bounds||self.bounds;
      if (bounds) {
        let map_bounds = new this.google.maps.LatLngBounds();
        map_bounds.extend(new this.google.maps.LatLng(bounds[0][0], bounds[0][1]));
        map_bounds.extend(new this.google.maps.LatLng(bounds[1][0], bounds[1][1]));
        searchParams.bounds = map_bounds;
      }

      let city = search.city||self.city;
      if (city) {
        let parts = search.address.split(',');
        if (parts.length < 2 || parts[1].length < 4) {
          parts[1] = city;
          searchParams.address = parts.join(',');
        }
      }

      this.geocoder
        .geocode(searchParams)
        .then((result) => {
          const {results} = result;
          let list: LookupResult[] = [];
          for (let i = 0; i < results.length; i++) {
            let select = results[i];
            let latLng = select.geometry.location;
            let viewPort = select.geometry.viewport;
            let location = select["formatted_address"];

            if(location) {
              let parts=location.split(',');
              parts.length=parts.length-1;
              location=parts.join(', ');
            }

            let result: LookupResult = {
              address: {
                address1: undefined,
                city: undefined,
                countryCode: undefined,
                county: undefined,
                postalCode: undefined,
                stateCode: undefined
              },
              bounds: undefined,
              location: location,
              x: undefined,
              y: undefined,
              id: undefined
            };

            if(location) {
              result.address.address1=location.split(',')[0];
            }

            let a=select["address_components"];
            if(a) {
              for(let i=0;i<a.length;i++) {
                if(a[i].types.indexOf("locality")>=0) {
                  result.address.city=a[i]["short_name"];
                  continue;
                }
                if(a[i].types.indexOf("administrative_area_level_2")>=0) {
                  result.address.county=a[i]["short_name"];
                  continue;
                }
                if(a[i].types.indexOf("administrative_area_level_1")>=0) {
                  result.address.stateCode=a[i]["short_name"];
                  continue;
                }
                if(a[i].types.indexOf("country")>=0) {
                  result.address.countryCode=a[i]["short_name"];
                  continue;
                }
                if(a[i].types.indexOf("postal_code")>=0) {
                  result.address.postalCode=a[i]["short_name"];
                  continue;
                }
                if(a[i].types.indexOf("postal_code_suffix")>=0) {
                  result.address.postalCode+="-"+a[i]["short_name"];
                }
              }
            }

            let ne=viewPort.getNorthEast();
            let sw=viewPort.getSouthWest();
            result.bounds=[
              [ne.lat(), sw.lng()],
              [sw.lat(), ne.lng()]
            ];
            result.y=latLng.lat();
            result.x=latLng.lng();

            list.push(result);
          }
          resolve(list);
        })
        .catch((e) => {
          reject();
        });
    })
  }
}

const GoogleLookup = new _GoogleLookup();
export default GoogleLookup;
