import {db} from "@/lib/db";

const knownAddressProviders: Record<string,any> = {
  "google": import('./googleLookup')
}

export class LookupResult {
  address: {
    address1:string|undefined,
    city:string|undefined,
    stateCode:string|undefined,
    countryCode:string|undefined,
    postalCode:string|undefined,
    county:string|undefined,
  }
  location:string|undefined
  x:number|undefined
  y:number|undefined
  bounds: number[][] | undefined
  id: any|undefined
}

export class LookupRequest {
  bounds: number[][]
  address: string
  city: string|undefined
}

export interface IAddressLookup {
  name: string;
  lookup: (search: LookupRequest) => Promise<LookupResult[]>;
  init: (options: any) => IAddressLookup;
}

class _AddressLookupFactory {
  defaultName: string;

  city:string|undefined
  bounds: number[][]|undefined
  providers: Record<string,IAddressLookup>
  knownProviders: Record<string,Promise<Record<any, IAddressLookup>>> = knownAddressProviders

  constructor() {
    this.defaultName = 'google';
    this.providers = {};

    let self = this;
    let oid = localStorage.getItem("oid");
    db['org'].where({"oid":oid}).each(o=>{
      if(o.bounds) self.bounds=o.bounds;
      if(o.city) self.city=o.city;
    });
  }

  getProvider(name: string):Promise<IAddressLookup> {
    let self= this;
    return new Promise<IAddressLookup>(async (resolve, reject) => {
      if(!name) {
        console.error("no address provider name given")
        reject();
        return;
      }
      if(self.providers[name]) {
        resolve(self.providers[name]);
        return;
      }

      let lazy = self.knownProviders[name];
      if(lazy) {
        lazy.then((provider)=>{
          provider.default.init({city:self.city,bounds:self.bounds});
          self.providers[name] = provider.default;
          resolve(provider.default);
        })
        return;
      }
      reject();
    });
  }

  getDefault():Promise<IAddressLookup> {
    return this.getProvider(this.defaultName);
  }
}

const AddressLookupFactory = new _AddressLookupFactory();

export default AddressLookupFactory;
